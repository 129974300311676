import React from 'react';
import Navbar from '../components/Navbar';

const Contact = () => {
return (
	<div>
        <Navbar />
	    <h1>So you're thinking to yourself, wow, this developer is so cool and handsome, I really want to meet up with him. </h1>
        <h1>Well you've come to the right page. Email me at support@10mb.app.</h1>
        <h1>Also chances are that if you are on this website you already have my number, so feel free to hit me up anytime bb &lt;3 ;)
    </h1>
	</div>
);
};

export default Contact;
