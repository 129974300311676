import React from "react";
import Navbar from '../components/Navbar';
  
const About = () => {
  return (
    <div>
      <Navbar />
      <h1>
        Unlike this website (and your mom), 10MB the mobile app is not actually completely ugly.
      </h1>
    </div>
  );
};
  
export default About;