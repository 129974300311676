import React from 'react';
import Navbar from '../components/Navbar';

const Home = () => {
return (
	<div>
		<Navbar />
		<h1>2004 Called. It wants its website back.</h1>
		<h1>In theory there's going to be a much prettier website here eventually, but who really knows when that's happening? In the meantime, enjoy this work of art.</h1>
	</div>
);
};

export default Home;
