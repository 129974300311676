import React from "react";
import { Nav, NavLink, NavMenu }
	from "./NavbarElements";

const Navbar = () => {
return (
	<>
	<Nav>
		
		<NavMenu>
		{/*
		<NavLink to="/" activeStyle>
			Pretty Website
		</NavLink>
		*/}
		<NavLink to="/" activeStyle>
			Home
		</NavLink>
		<NavLink to="/oldabout" activeStyle>
			About
		</NavLink>
		<NavLink to="/oldcontact" activeStyle>
			Contact Us
		</NavLink>
		<NavLink to="/oldblogs" activeStyle>
			Blogs
		</NavLink>
		
		<a href="https://apple.co/3s4qHHA">Download</a>

		{/*
		<NavLink to="https://apps.apple.com/us/app/10mb/id1599243769" activeStyle>
			Download
		</NavLink>
		*/}
		</NavMenu>
	</Nav>
	</>
);
};

export default Navbar;


