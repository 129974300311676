import React from 'react';
import Navbar from '../components/Navbar';
  
const SignUp = () => {
  return (
      
    <div>
        <Navbar />
      <h1>Ahh, so you want to sign up? Well boy do I have good news for you</h1>
    </div>
  );
};
  
export default SignUp;