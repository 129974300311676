import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route}
	from 'react-router-dom';
import Home from './pages';
import About from './pages/about';
import Blogs from './pages/blogs';
import SignUp from './pages/signup';
import Contact from './pages/contact';
// import Lava from './Pretty/lava';


function App() {
return (
	<Router>
	{/* <Navbar /> */}
	<Routes>
    {/* <Route exact path='/' element={<Lava/>} /> */}
		<Route exact path='/' element={<Home/>} />
		<Route path='/oldabout' element={<About/>} />
		<Route path='/oldcontact' element={<Contact/>} />
		<Route path='/oldblogs' element={<Blogs/>} />
		<Route path='/oldsign-up' element={<SignUp/>} />
	</Routes>
	</Router>
);
}

export default App;

