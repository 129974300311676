import React from 'react';
import Navbar from '../components/Navbar';
  
const Blogs = () => {
  return (
    <div>
      <Navbar />
      <h1>Did you really just click on this link to view my blog? What on earth do I look like? 
        I'm a computer science jock and intramurals champion, I don't have time to blog about my feelings.
      </h1>
    </div>
  );
};
  
export default Blogs;